//This file provides the function that retrieves data from a local
//text file.

//This imports the fetch function used below.
import 'whatwg-fetch';

//Function to retrieve the local file data.
//Input: The path to the local file from the public root directory.
//Output: A Promise with the file data as its value.
const getData = async (filePath) => {
    //Make a local fetch request.
    const response = await fetch(filePath);

    //Check for response status.  Note that 0 is a valid local 
    //fetch response.
    if (response.status === 200 || response.status === 0) {
        //Request is finished and successful.
        const data = await response.text();
        //Return data to caller.
        return data;
    } else {
        //Return rejected promise to caller via exception.
        throw new Error('An error has taken place');
    }
}

//Export getData(...) function as default.
export {getData as default}