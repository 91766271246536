//This file exports a function that provides uniform inner HTML
//for the <head> tags of all of the pages of the website.

//This imports adds a function that retrieves the text of a local
//file.
import getData from './locol-request';

//Function to insert html into heads of web pages that is
//exported.
export const loadHeaderHTML = async () => {
    try {
        //Retrieve the inner html.  Note that this is why a try-
        //catch block is needed.
        const rawData = await getData('./data/header-content.txt');

        //Add the retrived html the contents of the <head> tag.
        document.head.innerHTML += rawData;
    } catch (error) {
        //Show error message as the page title if the resume was
        //not retrieved.
        document.head.innerHTML = '<title>ERROR! ' + error
            + '</title>'; 
    }
}