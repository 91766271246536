//This is the code for the site's main page.

import {addFooter} from './footer';
import {addMenu} from './sliding-menu';
import {loadQandA} from './index-views'
import {loadHeaderHTML} from './head-html-getter';

//Add the site footer to this page.
addFooter();

//Add the initially-hidden sliding menu to the page.
addMenu();

//Add the HTML to the page header.
loadHeaderHTML();

//Load data from text file.
loadQandA();