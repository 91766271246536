//This file provides a function that will add a footer above the
//current page's <script> tag.

import $ from 'jquery';

const addFooter = () => {
    //This is the element to be added to the page. 
    const footerElement = document.createElement("div");
    footerElement.classList.add("banner");

    //This code creates and adds the "Site Navigation" label to the
    //footer.
    const labelContainerElement = document.createElement("div");
    labelContainerElement.classList.add("container");
    const labelElement = document.createElement("h2");
    labelElement.textContent = "Site Navigation";
    footerElement.appendChild(labelContainerElement);
    labelContainerElement.appendChild(labelElement);

    //This code creates and adds the button panel to the footer.

    //Part 1: Create the elements
    const buttonContainer = document.createElement("div");
    buttonContainer.classList.add("banner__container");
    const homeButton = document.createElement("button");
    homeButton.classList.add("button--nav");
    const resumeButton = document.createElement("button");
    resumeButton.classList.add("button--nav");
    const notesButton = document.createElement("button");
    notesButton.classList.add("button--nav");
    const todosButton = document.createElement("button");
    todosButton.classList.add("button--nav");
    const hangmanButton = document.createElement("button");
    hangmanButton.classList.add("button--nav");
    const recipesButton = document.createElement("button");
    recipesButton.classList.add("button--nav");

    //Part 2: Add text and listeners to buttons
    homeButton.textContent = "Home";
    homeButton.addEventListener('click', (e) => {
        location.assign('./');
    });
    resumeButton.textContent = "Resume";
    resumeButton.addEventListener('click', (e) => {
        location.assign('./resume.html');
    });
    notesButton.textContent = "Notes";
    notesButton.addEventListener('click', (e) => {
        location.assign('./notes-index.html');
    });
    todosButton.textContent = "Todos";
    todosButton.addEventListener('click', (e) => {
        location.assign('./todos-index.html');
    });
    hangmanButton.textContent = "Hangman";
    hangmanButton.addEventListener('click', (e) => {
        location.assign('./hangman-index.html');
    });
    recipesButton.textContent = "Recipes";
    recipesButton.addEventListener('click', (e) => {
        location.assign('./recipes-index.html');
    });

    //Part 3: Add buttons to footer
    buttonContainer.appendChild(homeButton);
    buttonContainer.appendChild(resumeButton);
    buttonContainer.appendChild(notesButton);
    buttonContainer.appendChild(todosButton);
    buttonContainer.appendChild(hangmanButton);
    buttonContainer.appendChild(recipesButton);
    footerElement.appendChild(buttonContainer);

    //This places the footer on the page above the final <script>
    //tag.
    $(footerElement).insertBefore("body script");
}

//This exports the function.
export {addFooter};