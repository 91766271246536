//This file contains functions for use when rendering DOM elements
//on the main page of the website.

import $ from 'jquery';
import getData from './locol-request';

//Function to load the Q and A.
export const loadQandA = async () => {
    //Get the page div that will hold the Q and A.
    const containerDiv = document.querySelector('#q-and-a');

    try {
        //Retrieve the Q and A.  Note that this is why a try-catch
        //block is needed.
        const rawData = await getData('./data/Q-and-A.txt');

        //Split the Q and A into lines.
        const dataArray = rawData.split('\n');

        //Loop through each question and answer pair, namely every
        //pair of lines in the raw data.
        for (let i = 0; i < dataArray.length; i += 2) {
            const questionText = dataArray[i];
            const answerText = dataArray[i + 1];

            //Define and get elements.
            const fullQuestionEl = document.createElement('label');
            const answerEl = document.createElement('p');
            const questionTextEl = document.createElement('span');
            const showTextEl = document.createElement('button');

            //Setup the button element that will show or hide the
            //answer.
            showTextEl.textContent = 'show';
            showTextEl.classList.add('button');
            showTextEl.addEventListener('click', () => {
                //Set the animation length.
                const time = 500;

                //Show or hide the answer with an animation.
                if (showTextEl.textContent === 'show') {
                    showTextEl.textContent = 'hide';
                } else {
                    showTextEl.textContent = 'show';
                }
                $(answerEl).stop().slideToggle(time);
            });

            //Setup the span that will show the question text.
            questionTextEl.textContent = questionText;

            //Setup the full question element.
            fullQuestionEl.classList.add('horizontal-list-item');
            fullQuestionEl.appendChild(questionTextEl);
            fullQuestionEl.appendChild(showTextEl);

            //Setup the answer element.
            answerEl.classList.add('answer');
            answerEl.innerHTML = answerText;

            //Add the elements to the Q and A div.
            containerDiv.appendChild(fullQuestionEl);
            containerDiv.appendChild(answerEl);
        }
    } catch (error) {
        //Show error message if the Q and A was not retrieved.
        containerDiv.innerHTML = 'ERROR!  ' + error; 
    }
}