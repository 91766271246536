//This file contains the method necessary to add a navigation menu
//that will show when an icon is clicked as well as its helper
//functions.

import $ from 'jquery';

//This function adds the icon that will show the sliding menu to
//any page that calls the main method. (NOT exported.)
const addIcon = () => {
    //Create the required elements.
   const divElement = document.createElement("div");
   const imageElement = document.createElement("img");

   //Add classes and attributes.
   divElement.classList.add("menu-icon");
   imageElement.setAttribute("src", "./images/menu.svg");
   imageElement.setAttribute("alt", "menu");

   //Place elements on page.
   divElement.appendChild(imageElement);
   $(divElement).prependTo("body");

   //Add code to launch menu.
   imageElement.addEventListener("click", (e) => {
       e.preventDefault();
       $(".menu").stop().slideDown(1000);
   });
}

//This helper function adds the sliding menu div to any page that
//calls the main method.  Due to CSS formatting, the div is 
//initially hidden. (NOT exported.)
const addMenuDiv = () => {
    //This is the element to be added to the page. 
    const menuElement = document.createElement("div");
    menuElement.classList.add("menu");

    //This code creates and adds the button panel to the menu.

    //Part 1: Create the elements
    const homeButton = document.createElement("button");
    homeButton.classList.add("button--nav");
    const resumeButton = document.createElement("button");
    resumeButton.classList.add("button--nav");
    const notesButton = document.createElement("button");
    notesButton.classList.add("button--nav");
    const todosButton = document.createElement("button");
    todosButton.classList.add("button--nav");
    const hangmanButton = document.createElement("button");
    hangmanButton.classList.add("button--nav");
    const recipesButton = document.createElement("button");
    recipesButton.classList.add("button--nav");
    const closeButton = document.createElement("button");
    closeButton.classList.add("button--nav");

    //Part 2: Add text and listeners to buttons
    homeButton.textContent = "Home";
    homeButton.addEventListener('click', (e) => {
        location.assign('./');
    });
    resumeButton.textContent = "Resume";
    resumeButton.addEventListener('click', (e) => {
        location.assign('./resume.html');
    });
    notesButton.textContent = "Notes";
    notesButton.addEventListener('click', (e) => {
        location.assign('./notes-index.html');
    });
    todosButton.textContent = "Todos";
    todosButton.addEventListener('click', (e) => {
        location.assign('./todos-index.html');
    });
    hangmanButton.textContent = "Hangman";
    hangmanButton.addEventListener('click', (e) => {
        location.assign('./hangman-index.html');
    });
    recipesButton.textContent = "Recipes";
    recipesButton.addEventListener('click', (e) => {
        location.assign('./recipes-index.html');
    });
    closeButton.textContent = "Close Menu";
    closeButton.addEventListener('click', (e) => {
        $(".menu").stop().slideUp(1000);
    });

    //Part 3: Add buttons to menu
    menuElement.appendChild(homeButton);
    menuElement.appendChild(resumeButton);
    menuElement.appendChild(notesButton);
    menuElement.appendChild(todosButton);
    menuElement.appendChild(hangmanButton);
    menuElement.appendChild(recipesButton);
    menuElement.appendChild(closeButton);

    //This places the menu on the page in an initially hidden 
    //state.
    $(menuElement).prependTo("body");
}

//This is the main (exported) method.  It add the sliding menu and
//the icon that will the the menu to the calling page.
export const addMenu = () => {
    addMenuDiv();
    addIcon();
}